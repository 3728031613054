.passPromptConfirm {
    display: flex;
    align-items: center;
    width: 300px;
    background-color: #ffffff;
    margin-bottom: 9px;
    border: 1px solid rgb(106, 106, 106); /* simplified the border */
    border-radius: 5px;
    overflow: hidden;
  }

  .passPromptConfirm:focus-within {
    border: 2px solid rgb(60, 60, 60);
  }
  
  .passPromptConfirm #password:focus, #passConfirm:focus {
    outline: none;
  }

  .passPromptConfirm #passConfirm {
    padding: 9px;
  }
  
  .passPromptConfirm .showPassButtonOn,
  .passPromptConfirm .showPassButtonOff {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    border: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
    margin-right: 9px;
    margin-left: 72px;
  }

  #noMatch {
    position:relative;
    color: red;
    font-size: 15px;
    padding-left: 9px;
  }

  .passPromptConfirm .showPassButtonOn {
    background-image: url("../icons/showOn.svg");
  }
  
  .passPromptConfirm .showPassButtonOff {
    background-image: url("../icons/showOff.svg");
  }