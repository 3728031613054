.user-profile-canvas {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
}

.user-profile-box {
	position: relative;
	z-index: 1001;
	max-height: 80vh;
	width: 70%;
	min-width: 402px;
	max-width: 600px;
	background-color: white;
	padding: 30px;
	box-sizing: border-box;
	border-radius: 9px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	overflow-y: auto;
}

.user-profile-box .user-profile-info {
	display: flex;
	flex-direction: row;
	gap: 12px;
}

.user-profile-canvas #left {
	width: 40%;
	margin-right: 20px;
}

.user-profile-canvas #left img {
	border-radius: 15px;
	border: 1px solid #ccc;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
}

.user-profile-canvas #right {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	gap: 12px;
}

.user-profile-canvas #right #bottom {
	align-self: flex-end;
	
}

.user-profile-info #close-button {
	position: absolute;
	cursor: pointer;
	top: 20px;
	right: 20px;
}
