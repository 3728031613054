  .register-box {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
    margin: auto;
    transform: translateY(5%);
    width: 400px;
    border-radius: 50px;
    border: 1px solid gray;
  }
  
  .register-box #title {
    font-size: 50px;
    padding-bottom: 30px;
    font-weight: bolder;
  }
  
  .register-box #registerBtn {
    color: #ffffff;
    background-color: #c6c0cb;
    box-shadow: "2px 4px 6px black";
    border: 0.1em solid #ffffff;
    width: auto;
    margin-top: 25px;
    font-size: 130%;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    letter-spacing: 2px;
    border-radius: 20px;
  }
  