.user-input #user {
    display: flex;
    align-items: center;
    width: 300px;
    padding: 9px;
  
    background-color: #ffffff;
    margin-bottom: 9px;
    margin-top: 20px;
    border: 1px solid rgb(106, 106, 106); /* simplified the border */
    border-radius: 5px;
    overflow: hidden;
  }