.carousel {
    position: relative;
    width: 100%;
    aspect-ratio: 21/10;
    overflow: hidden;
}

.carousel-slide-container {
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 300ms ease-in-out;
}

.carousel-slide {
    flex: 0 0 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 2rem;
    height: 2rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
}

.carousel-arrow-left {
    left: 1rem;
}

.carousel-arrow-right {
    right: 1rem;
}

.carousel-indicators {
    display: flex;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
}

.carousel-indicator {
    background-color: white;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    border: none;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin: 0 0.2rem;
    cursor: pointer;
}

.carousel-indicator-inactive {
    background-color: rgba(255, 255, 255, 0.5);
}
