.lodgment-adder-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lodgment-adder-box {
  z-index: 1001;
  max-height: 80vh;
  width: auto;
  min-width: 402px;
  max-width: 32.67%;
  background-color: white;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 15px;
  margin: 20px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.lab-upload {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  max-width: 100%;
}

.lab-title-box {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.lab-title {
  width: 100%;
  padding: 6px;
  border: 1.5px solid rgb(130, 130, 130);
  border-radius: 6px;
}

.lab-title:focus {
  outline: none;
  border-color: #5dabff;
  box-shadow: 0 0 9px rgba(0, 123, 255, 0.5);
}

#lab-title-remaining-chars {
  font-size: 0.8em;
  color: black;
  margin-left: auto;
}

.lab-description-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.lab-brief-description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.lab-description {
  width: 100%;
  padding: 6px;
  border: 1.5px solid rgb(130, 130, 130);
  border-radius: 6px;
  min-height: 60px;
}

.lab-description:focus {
  outline: none;
  border-color: #5dabff;
  box-shadow: 0 0 9px rgba(0, 123, 255, 0.5);
}

#lab-description-remaining-chars {
  font-size: 0.8em;
  color: black;
  margin-left: auto;
}

.lab-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-self: flex-end;
}

.lab-buttons #lab-cancel {
  padding: 9px;
  margin-right: 15px;
}

.lab-buttons #lab-publish {
  background-color: #e04943;
  color: white;
  padding: 7px;
  border-radius: 5px;
}
