/* Estilos globales limitados */
.user-page html, 
.user-page body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden; /* Ocultar el scroll general si no se necesita */
}

/* Contenedor principal */
.user-page {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f7f5f9;
  overflow-y: auto;
}

/* Sección de perfil */
.user-page .profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.user-page .profile-section img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
  border: 2px solid #d3d3d3;
}

.user-page .edit-button {
  padding: 10px 15px;
  font-size: 14px;
  color: #ffffff;
  background-color: #50545a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-page .edit-button:hover {
  background-color: #646870;
}

/* Sección de información */
.user-page .info-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 400px;
}

.user-page label {
  font-size: 16px;
  font-weight: bold;
  color: #646870;
}

/* Inputs */
.user-page input[type="text"],
.user-page input[type="email"],
.user-page input[type="password"] {
  padding: 10px;
  margin-top: 5px;
  font-size: 14px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  background-color: #f7f5f9;
  color: #50545a;
}

.user-page .input-with-icon {
  display: flex;
  align-items: center;
  position: relative;
  background-color: #f7f5f9;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}

.user-page .input-with-icon input {
  flex: 1;
  border: none;
  background: none;
  outline: none;
  padding: 10px;
  font-size: 14px;
  color: #50545a;
}

.user-page .material-symbols-outlined {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #50545a;
}

/* Lista desplegable */
.user-page .domain-dropdown {
  position: absolute;
  z-index: 10;
  background-color: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: calc(100% - 22px);
  max-height: 150px;
  overflow-y: auto;
  margin-top: 5px;
  padding: 5px 0;
  list-style: none;
}

.user-page .domain-item {
  padding: 10px;
  font-size: 14px;
  color: #50545a;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.user-page .domain-item:hover {
  background-color: #f0f0f0;
}

/* Botones confirmación */
.user-page .confirm-button {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background-color: #50545a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-page .confirm-button:hover {
  background-color: #646870;
}

.user-page .confirm-button:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

/* Botones adicionales */
.user-page .change-password-button,
.user-page .add-university-email {
  padding: 10px 134px;
  font-size: 14px;
  color: #ffffff;
  background-color: #646870;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-page .change-password-button:hover,
.user-page .add-university-email:hover {
  background-color: #50545a;
}

/* Secciones adicionales */
.user-page .add-mail-section,
.user-page .delete-mail-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
}

/* Botón para cerrar sesión */
.user-page .logout-button {
    margin-top: 20px; /* Agregar un margen más pequeño */
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background-color: #d9534f; /* Rojo para destacar */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: center; /* Centrar horizontalmente el botón */
  }
  
  .user-page .logout-button:hover {
    background-color: #c9302c; /* Rojo más oscuro en hover */
  }
  
