  .RegMail {
    display: flex;
    width: 300px;
    background-color: #ffffff;
    margin-bottom: 9px;
    border: 1px solid rgb(106, 106, 106);
    border-radius: 5px;
  }
  
  .RegMail #data {
    padding: 9px;
    min-width: 0;
    border-radius: 5px;
  }
  
  .RegMail #value {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 9px;
    border-left: 1px solid rgb(106, 106, 106);
    border-right: 1px solid rgb(106, 106, 106);
  }
  
  .RegMail #dropdownMenu {
    position: absolute;
    text-align: center;
    top: 110%;
    padding: inherit;
    background-color: white;
    border: 0.2px solid rgb(106, 106, 106);
    border-radius: 3px;
  }
  
  .RegMail #dropdownButton {
    background-image: url("./icons/arrowDown.svg");
    background-size: cover;
    padding: 20px;
  }