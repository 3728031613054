.lodgment-detail-page {
	display: flex;
	width: 100%;
	height: 100%;
	padding: 3%;
	justify-content: center;
}

.lodgment-detail-page {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#carousel-container {
	width: 60%;
	display: flex;
}

.lodgment-detail-page #text-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 60%;
	padding: 15px;
}

.lodgment-detail-page #author-info {
	display: flex;
	align-self: flex-start;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: 15px;
	gap: 10px;
}

.lodgment-detail-page #author-avatar {
	border-radius: 50%;
	object-fit: cover;
	aspect-ratio: 1 / 1;
	overflow: hidden;
	box-shadow: inset 0 0 160px rgba(0, 0, 0, 0.2), 0 0 15px rgb(100, 100, 100);
	width: 140px;
	height: 140px;
	min-height: 140px;
	max-height: 140px;
	min-width: 140px;
	max-width: 140px;
}

.lodgment-detail-page #location {
	justify-content: center;
}

.lodgment-detail-page .lodgment-admin-delete {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.lodgment-detail-page .delete-lodgment-button {
	align-self: center;
	color: #ca4653;
	cursor: pointer;
}

.lodgment-detail-page .delete-lodgment-modal {
	position: relative;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	padding: 15px;
	border-radius: 5px;
	background-color: #f8d7da;
	color: #721c24;
	border: 1px solid #f5c6cb;
	width: 140px;
}

#text-container #text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	align-self: flex-start;
	width: 100%;
	height: 100%;
	padding: 15px;
	padding-left: 33px;
	gap: 10px;
}

#text-container hr {
	width: 100%;
	border: 2px solid #9c9c9c;
	border-radius: 5%;
	margin: 20px 0;
}

#text #description {
	word-wrap: break-word;
	overflow-wrap: break-word;
	word-break: break-word;
	white-space: pre-wrap;
	max-width: 100%; 
}

#text #description h3,
h2,
h1 {
	font-weight: bold;
}

#text #description h1 {
	font-size: 1.75em;
}

#text #description h2 {
	font-size: 1.5em;
}

#text #description h3 {
	font-size: 1em;
}

#text #description ul {
	list-style-type: disc;
	margin-left: 20px;
	padding-left: 20px;
}

#text #description ol {
	list-style-type: decimal;
	margin-left: 20px;
	padding-left: 20px;
}

#text #description li {
	margin-bottom: 10px;
}

#text #description .ql-font-monospace {
	font-family: monospace;
}

#text #description .ql-font-serif {
	font-family: serif;
}

#text #description .ql-align-justify {
	text-align: justify;
}

#text #description .ql-align-center {
	text-align: center;
}

#text #description a {
	color: #007bff;
	text-decoration: 1.25px underline;
}

#text #description img {
	width: 100%;
	margin: 10px 0;
	align-content: center;
	padding: 10px;
}
