.reset-password-box {
	background-color: #ffffff;
	min-height: 60vh;
	transform: translateY(10%);
	margin: auto;
	width: 400px;
	border-radius: 50px;
	border: 2px solid black;
    display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.reset-password-box #title {
	font-size: 50px;
    margin-top: 15px;
	padding-bottom: 30px;
	font-weight: bolder;
    text-align: center;
}

.reset-password-box #change-password-button {
	color: #ffffff;
	background-color: #c6c0cb;
	box-shadow: "2px 4px 6px black";
	border: 0.1em solid #ffffff;
	width: auto;
	margin-top: 25px;
    margin-bottom: 15px;
	font-size: 130%;
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	letter-spacing: 2px;
	border-radius: 20px;
}

.reset-password-box #mail-sent {
    color: black;
    text-decoration: none;
    font-size: 1em;
    margin: 10%;
    margin-top: 10px;
	margin-bottom: 10px;
    text-align: center;
}