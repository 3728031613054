.verification-box {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    margin: auto;
    transform: translateY(20%);
    width: 400px;
    padding: 50px;
    border-radius: 50px;
    border: 1px solid gray;
    text-align: center;
  }
  
  .verification-box #title {
    font-size: 30px;
    padding-bottom: 10px;
    font-weight: bolder;
    padding-bottom: 30px;
  }