.passPrompt {
    display: flex;
    align-items: center;
    width: 300px;
    background-color: #ffffff;
    border: 1px solid grey; /* simplified the border */
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
  }

  .passPrompt:focus-within {
    border: 2px solid rgb(60, 60, 60);
  }
  
  .passPrompt #password:focus {
    outline: none;
  }

  .passPrompt #password {
    padding: 9px;
  }
  
  .passPrompt .showPassButtonOn,
  .passPrompt .showPassButtonOff {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    border: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
    margin-right: 9px;
    margin-left: 72px;
  }

  .passPrompt .showPassButtonOn {
    background-image: url("../icons/showOn.svg");
  }
  
  .passPrompt .showPassButtonOff {
    background-image: url("../icons/showOff.svg");
  }