.lodgment-editor-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lodgment-editor-box {
  z-index: 1001;
  max-height: 80vh;
  width: auto;
  min-width: 402px;
  max-width: 32.67%;
  background-color: white;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
}

.lodgment-editor-box .my-lodgments-card {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    flex-direction: column;
}