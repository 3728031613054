/* General Reset */
* {
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Body Styles */
body {
  margin: 0;
  color: #333;
  overflow-x: hidden;
}

/* App Wrapper */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Section Styles */
.section {
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s, opacity 0.3s;
  border-radius: 8px;
  margin: 1rem;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.8);
}

/* Footer Styles */
.footer {
  background-color: #344955;
  color: white;
  text-align: center;
  padding: 1rem 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}

/* Support Section Layout */
.Support {
  padding: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  height: calc(100vh - 150px);
  box-sizing: border-box;
}

/* Contact Us Form */
.contactUs {
  max-width: 40%;
  padding: 0;
}

#form input {
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  border: none;
  border-bottom: 2px solid rgb(211, 211, 211);
  outline: none;
  transition: border-bottom 0.3s ease;
}

#form input:focus {
  border-bottom: 2px solid black;
}

#formSend {
  color: #ffffff;
  background-color: #344955;
  width: 100%;
  margin-top: 25px;
  font-weight: lighter;
  font-size: 110%;
  padding: 4%;
  letter-spacing: 2px;
  border: none;
  cursor: pointer;
}

#contactTitle {
  padding-bottom: 2%;
  font-size: 200%;
  font-weight: bold;
}

#contactDescription {
  font-weight: lighter;
  font-size: 110%;
}

/* Contenedor del título de información */
#infoHeader {
  margin-bottom: 20px; /* Espaciado entre el título y la lista */
}

/* Título de información */
#infoTitle {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin: 0; /* Elimina cualquier margen por defecto */
  padding-bottom: 10px; /* Espaciado adicional debajo del título */
  border-bottom: 2px solid #fff; /* Línea decorativa opcional */
}

/* Lista de datos */
#dataList {
  font-size: 18px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  list-style: none;
}

#dataList li {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  gap: 10px;
}

#dataList li:last-child {
  margin-bottom: 0;
}

/* Icons for Info List */
#mail::before,
#phone::before,
#location::before,
#workHours::before {
  content: url('./icons/mail_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg');
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

#phone::before {
  content: url('./icons/call_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg');
}

#location::before {
  content: url('./icons/location_on_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg');
}

#workHours::before {
  content: url('./icons/schedule_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg');
}
