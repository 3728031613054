.lodgment-adder-button-box {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.lodgment-adder-button {
  background-color: #c6c0cb;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 65%;
  color: white;
  font-weight: bold;
  border-radius: 1rem;
}

#my-lodgments {
  width: 40px;
  height: 40px;
  background-image: url("../../images/icons/edit.svg");
}

#add-lodgments {
  width: 40px;
  height: 40px;
  background-image: url("../../images/icons/add.svg");
}

#hover-button {
  width: 60px;
  height: 60px;
  background-image: url("../../images/icons/options.svg");
  transition: none;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.button-container.hide {
  transform: translateY(10px);
  opacity: 0;
}

.button-container.show {
  transform: translateY(0);
  opacity: 1;
}