.quill-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
}

.ql-toolbar {
  margin-bottom: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.ql-container {
  border-top: none;
}
