.geoconverter-bar {
    width: 100%;
    max-width: 700px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.geoconverter-input {
    width: 100%;
    padding: 6px;
    border: 1.5px solid rgb(130, 130, 130);
    border-radius: 6px;
    font-size: 1rem;
}

.geoconverter-input:focus {
    outline: none;
    border-color: #5dabff;
    box-shadow: 0 0 9px rgba(0, 123, 255, 0.5);
}

.geoconverter-suggestions {
    border: 1.5px solid rgb(130, 130, 130);
    background-color: white;
    box-sizing: border-box;
    position: absolute;
    top: 45px;
    border-radius: 9px;
    list-style: none;
    margin-top: 5px;
    z-index: 1001;
    width: 100%;
    padding: 0;
}

.geoconverter-suggestion {
    padding: 10px;
    cursor: pointer;
    width: auto;
}